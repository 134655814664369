


































































































import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { SurveyResponse } from "@/interfaces/data_objects/user";
import { GET_COMPANY_DETAILS } from "@/store/modules/auth/constants";
import {
  APP_ASSESSMENT_RESULT,
  APP_ASSESSMENTS,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import {
  GET_SURVEY_QUESTIONS,
  SUBMIT_SURVEY_QUESTIONS,
  UPDATE_APPROVAL_CRITERIA
} from "@/store/modules/recruiter/constants";
import { UpdateApprovalCriteriaPayload } from "@/store/modules/recruiter/interfaces";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { CHECK_SURVEY_ALREADY_SUBMITTED } from "@/store/modules/candidates/constants";

export default Vue.extend({
  components: { GoBackHeader },
  name: "SurveyQustions",
  data() {
    return {
      answer: 3,
      questions: [] as SurveyResponse[],
      SiteDirections: SiteDirections,
      eng_version: "",
      ann_version: "",
      assessment_done: false,
      loading: true,
      survey_already_submitted: false
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_survey_questions: GET_SURVEY_QUESTIONS
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION,
      app_assessments: APP_ASSESSMENTS
    }),
    ...mapGetters("auth", {
      get_company_details: GET_COMPANY_DETAILS
    })
  },
  async mounted() {
    this.survey_already_submitted = await this.check_survey_submitted();
    console.log(this.survey_already_submitted);
    // Find English Assessment
    this.eng_version =
      this.get_company_details.s3_path.assessment_setting.candidates_english;
    this.ann_version =
      this.get_company_details.s3_path.assessment_setting.candidates_annotation;

    this.find_assessment(this.eng_version, this.ann_version);
    await this.fetch_survey_questions();
    this.questions = this.get_survey_questions.map(
      (question: {
        id: number;
        text: string;
        type: string;
        text_arabic: string;
      }) => {
        return {
          id: question.id,
          question: question.text,
          question_arabic: question.text_arabic,
          type: question.type,
          answer: question.type == "rating" ? 0 : ""
        };
      }
    );
  },
  methods: {
    ...mapActions("recruiter", {
      submit_survey: SUBMIT_SURVEY_QUESTIONS,
      fetch_survey_questions: GET_SURVEY_QUESTIONS
    }),
    ...mapActions("candidate", {
      check_survey_submitted: CHECK_SURVEY_ALREADY_SUBMITTED
    }),
    ...mapActions("common", {
      app_assessment_result: APP_ASSESSMENT_RESULT
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    async find_assessment(english_version: string, annotation_version: string) {
      let englishAssessmentExist = {} as AppAssessments.AssessmentResults;
      let annotationAssessmentExist = {} as AppAssessments.AssessmentResults;
      let eng_done = false;
      let anno_done = false;
      const get_assessments = async (
        english_version: string,
        annotation_version: string
      ) => {
        this.loading = true;

        const english_assessment = this.app_assessments.find(
          (val: AppAssessments.Assessment) =>
            val.assessment_type === english_version
        );
        // Check if english assessment is attempted or not
        if (english_assessment) {
          englishAssessmentExist = await this.app_assessment_result({
            assessment_id: english_assessment.id
          });
          if (englishAssessmentExist) {
            eng_done = true;
          } else {
            eng_done = false;
          }
        }

        // Find Annotation Assessment
        const annotation_assessment = this.app_assessments.find(
          (val: AppAssessments.Assessment) =>
            val.assessment_type === annotation_version
        );
        // Check if annotation assessment is attempted or not
        if (annotation_assessment) {
          annotationAssessmentExist = await this.app_assessment_result({
            assessment_id: annotation_assessment.id
          });
          if (annotationAssessmentExist) {
            anno_done = true;
          } else {
            anno_done = false;
          }
        }

        this.assessment_done = eng_done && anno_done;
        this.loading = false;
      };

      await get_assessments(english_version, annotation_version);
      // Check if english and annotation assessment version exist or not
      if (!englishAssessmentExist) {
        switch (english_version) {
          case AppAssessmentNames.english_v1:
            english_version = AppAssessmentNames.english_v1;
            break;
          case AppAssessmentNames.english_v2:
            english_version = AppAssessmentNames.english_v2;
            break;
          case AppAssessmentNames.english_v3:
            english_version = AppAssessmentNames.english_v3;
            break;
          default:
            english_version = AppAssessmentNames.english_v1; // Fallback to the first version
        }
      }
      if (!annotationAssessmentExist) {
        switch (annotation_version) {
          case AppAssessmentNames.annotation_v1:
            annotation_version = AppAssessmentNames.annotation_v2;
            break;
          case AppAssessmentNames.annotation_v2:
            annotation_version = AppAssessmentNames.annotation_v3;
            break;
          case AppAssessmentNames.annotation_v3:
            annotation_version = AppAssessmentNames.annotation_v3;
            break;
          default:
            annotation_version = AppAssessmentNames.annotation_v1; // Fallback to the first version
        }
      }
      if (!englishAssessmentExist || !annotationAssessmentExist) {
        englishAssessmentExist = {} as AppAssessments.AssessmentResults;
        annotationAssessmentExist = {} as AppAssessments.AssessmentResults;
        await get_assessments(english_version, annotation_version);
      }
    },
    // Function to update company assessment setting
    async saveChanges() {
      // API Call
      // check if all questions are answered
      const isAllQuestionsAnswered = this.questions.every(
        (question: { answer: string }) => question.answer !== ""
      );
      if (!isAllQuestionsAnswered) {
        this.set_root_error(this.$t("survey_questions.answer-all-questions"));
        return;
      }
      // know we will create payload of array with question_id and response
      const responses = this.questions.map(
        (question: { id: number; answer: string }) => {
          return {
            question_id: question.id,
            response: question.answer.toString()
          };
        }
      );
      const payload = {
        responses
      };
      const response = await this.submit_survey(payload);
      if (response) {
        this.set_root_notification(this.$t("survey_questions.survey-success"));
        this.$router.push("/candidate/dashboard");
      }
    },

    // Implement the logic to save changes
    // Function to filter locations
    filterLocation(item: string, query: string) {
      return item.toLowerCase().includes(query.toLowerCase());
    }
  }
});
